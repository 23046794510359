<div class="p-6 flex flex-col gap-6 h-full">
  <button mat-button routerLink="../.." class="self-start">
    <mat-icon>chevron_left</mat-icon>
    Back to training
  </button>
  <mat-tab-group
    mat-stretch-tabs="false"
    class="text-gray-500 h-full allow-tabs-grow">
    <mat-tab label="Preview" bodyClass="!grow">
      @if (materialUrl$ | async; as materialUrl) {
        <cat-ai-material-preview
          [url]="materialUrl.url"
          [loading]="loadingFile"
          (handleError)="handleFileViewError($event)"></cat-ai-material-preview>
      }
    </mat-tab>
  </mat-tab-group>
</div>
