<div class="{{ wrapperClasses }}">
  @for (notification of notifications; track notification.id) {
    <div
      class="border rounded-lg bg-gray-25 flex flex-row gap-5 justify-between p-4 text-sm">
      <button
        class="flex flex-row gap-4 text-start"
        (click)="navigateTo.emit(notification)">
        <div
          class="h-fit p-2 flex items-center justify-center text-center rounded-full cat-urgent-badge-{{
            notification.urgency
          }}">
          <mat-icon class="material-icons-outlined text-base">
            {{ notification | notificationIcon }}
          </mat-icon>
        </div>

        <div>
          <p class="font-semibold text-gray-900">
            {{ notification.title | titlecase }}
          </p>
          <p class="text-gray-600 max-h-10 overflow-hidden">
            {{ notification.message }}
          </p>
        </div>
      </button>
      <div class="flex flex-col items-end">
        <button (click)="dismiss.emit(notification.id)">
          <mat-icon class="text-sm">close</mat-icon>
        </button>
        @if (showTimeAgo) {
          <p class="text-gray-500">
            {{ notification.created_at | timeAgo }}
          </p>
        }
      </div>
    </div>
  } @empty {
    <div class="w-full h-full flex items-center justify-center text-center">
      <p class="text-gray-500">No unread notifications</p>
    </div>
  }
</div>
