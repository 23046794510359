@if (actions) {
  <div>
    @if (actions.download) {
      <button
        mat-icon-button
        matTooltip="Download"
        matTooltipPosition="above"
        type="button"
        (click)="download.emit(data)">
        <mat-icon class="material-icons-outlined">download</mat-icon>
      </button>
    }
    @if (actions.edit) {
      <button
        mat-icon-button
        matTooltip="Edit"
        matTooltipPosition="above"
        type="button"
        (click)="edit.emit(data)">
        <mat-icon class="material-icons-outlined">edit</mat-icon>
      </button>
    }
    @if (actions.print) {
      <button
        mat-icon-button
        type="button"
        matTooltip="Print"
        matTooltipPosition="above"
        (click)="print.emit(data)"
        [disabled]="data.disabledPrintAction">
        <mat-icon class="material-icons-outlined">print</mat-icon>
      </button>
    }
    @if (actions.assign) {
      <button mat-icon-button type="button" (click)="deleteAction()">
        <mat-icon class="material-icons-outlined">group_add</mat-icon>
      </button>
    }
    @if (actions.changeStatus) {
      <button mat-icon-button type="button" (click)="deleteAction()">
        <mat-icon class="material-icons-outlined">change_circle</mat-icon>
      </button>
    }
    @if (actions.viewDocument) {
      <button
        mat-icon-button
        matTooltip="View report"
        matTooltipPosition="above"
        type="button"
        (click)="viewDocument.emit(data)">
        <mat-icon class="material-icons-outlined">description</mat-icon>
      </button>
    }
    @if (actions.search) {
      <button
        mat-icon-button
        matTooltip="View"
        matTooltipPosition="above"
        type="button"
        (click)="search.emit(data)">
        <mat-icon class="material-icons-outlined">
          {{ searchActionIcon || 'search' }}
        </mat-icon>
      </button>
    }
    @if (actions.remove) {
      <button
        mat-icon-button
        matTooltip="Delete"
        matTooltipPosition="above"
        type="button"
        (click)="deleteAction()">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
    }
    @if (actions.archive) {
      <button
        mat-icon-button
        matTooltip="Archive"
        matTooltipPosition="above"
        type="button"
        (click)="archive.emit(data)"
        [disabled]="data.disabledArchieveAction">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
      </button>
    }
  </div>
}
