/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PrivateTrainingStats } from './private-training-stats';
import { Task } from './task';
import { ShallowUser } from './shallow-user';
import { CompanyGroup } from './company-group';
import { JobCategory } from './job-category';
import { PrivateQuestion } from './private-question';
import { MaterialLink } from './material-link';

export interface PrivateTraining {
  readonly id: number;
  name: string;
  description: string;
  cover: string;
  start: string;
  end: string;
  /**
   * * `draft` - Draft * `active` - Active * `completed` - Completed * `failed` - Failed * `archived` - Archived
   */
  status?: PrivateTraining.StatusEnum;
  certificate_valid_until?: string | null;
  materials?: Array<MaterialLink>;
  seconds_to_complete?: number | null;
  number_of_attempts?: number;
  pass_score: number;
  readonly created_at: string;
  created_by?: ShallowUser;
  readonly task: Task;
  questions: Array<PrivateQuestion>;
  groups?: Array<CompanyGroup>;
  job_categories?: Array<JobCategory>;
  stats: PrivateTrainingStats;
}
export namespace PrivateTraining {
  export type StatusEnum =
    | 'draft'
    | 'active'
    | 'completed'
    | 'failed'
    | 'archived';
  export const StatusEnum = {
    Draft: 'draft' as StatusEnum,
    Active: 'active' as StatusEnum,
    Completed: 'completed' as StatusEnum,
    Failed: 'failed' as StatusEnum,
    Archived: 'archived' as StatusEnum,
  };
}
