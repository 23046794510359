import { Pipe, PipeTransform } from '@angular/core';
import { MaterialRead } from '@cat-ai-us-fe/api';
import { getMaterialUrl } from '../../helpers/get-material-url';

@Pipe({
  name: 'getMaterialUrl',
  standalone: true,
})
export class GetMaterialUrlPipe implements PipeTransform {
  transform(value: MaterialRead): string | undefined {
    return getMaterialUrl(value);
  }
}
