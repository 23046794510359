<div
  class="max-w-md w-full bg-white absolute z-[100] right-0 top-0 bottom-0 shadow-xl p-4 overflow-y-auto flex flex-col">
  <div
    class="w-full flex flex-row justify-between items-center py-6 overflow-y-auto">
    <h3>Notifications</h3>
    <button mat-button color="primary" [disabled]="!notifications">
      <span class="underline" (click)="dismissAll()">Clear all</span>
    </button>
  </div>
  <cat-ai-notifications-list
    [notifications]="notifications"
    (dismiss)="dismiss($event)"
    (navigateTo)="notificationService.navigateTo($event)"
    wrapperClasses="flex flex-col gap-3 overflow-y-auto grow"></cat-ai-notifications-list>
</div>
