<header
  class="flex h-full justify-between items-center py-3 px-6 bg-white border-b border-gray-100">
  <!-- Page title -->

  @if (subTitle) {
    <span class="flex gap-2 items-baseline">
      <p class="text-gray-400 text-base">{{ pageTitle }}/</p>
      <h3>{{ subTitle }}</h3>
    </span>
  } @else {
    <h2>{{ pageTitle }}</h2>
  }
  <div class="flex items-center h-full">
    <!-- Notifications -->
    <button class="relative mr-6" (click)="togleDiv($event)">
      <mat-icon class="material-icons-outlined">notifications</mat-icon>
      @if (notifications && notifications.length) {
        <div
          class="w-1.5 h-1.5 bg-red-500 absolute top-0 right-0 rounded-full"></div>
      }
    </button>
    <!-- User profile menu -->
    @if (userName) {
      <cat-ai-avatar
        [fullName]="userName"
        [url]="avatar"
        size="big"></cat-ai-avatar>
    }
    <button
      mat-button
      [matMenuTriggerFor]="menu"
      aria-label="Example icon-button with a menu"
      class="!h-full !pr-0">
      <h3 class="text-primary-1000 !m-0">
        {{ userName }}
      </h3>
      <mat-icon iconPositionEnd>expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <a mat-menu-item routerLink="/settings">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </a>

      <button mat-menu-item>
        <mat-icon>quiz</mat-icon>
        <span>Help and support</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="policies">
        <mat-icon>policy</mat-icon>
        <span>Privacy</span>
      </button>
      <button mat-menu-item (click)="onLogout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
    <mat-menu #policies="matMenu">
      <a href="/policies/privacy">
        <button mat-menu-item>
          <span>Privacy Policy</span>
        </button>
      </a>
      <a href="/policies/security">
        <button mat-menu-item>
          <span>Security Policy</span>
        </button>
      </a>
      <a href="/policies/terms">
        <button mat-menu-item>
          <span>Terms of Service</span>
        </button>
      </a>
    </mat-menu>
  </div>
</header>
