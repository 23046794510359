<aside
  class="h-full bg-white flex flex-col border-r border-gray-100 w-max min-w-[256px]"
  [ngClass]="{
    'py-8': type === SidebarType.ROOT,
    'pb-8': type === SidebarType.SETTINGS,
  }">
  @if (type === SidebarType.ROOT) {
    <div class="px-4">
      <img src="assets/logo.png" alt="Logo" class="w-12 mb-4 ml-2" />
    </div>
  } @else if (type === SidebarType.SETTINGS) {
    <div class="h-[73px] flex items-center border-b mb-6 px-4">
      <a routerLink="/" mat-button>
        <mat-icon>chevron_left</mat-icon>
        Back to workspace
      </a>
    </div>
  }

  <div class="flex flex-col w-full gap-1 px-4">
    @if (type === SidebarType.ROOT) {
      @for (button of rootButtons; track button.icon) {
        <button
          #rla1="routerLinkActive"
          mat-flat-button
          [color]="
            (rla1.isActive && !button.subMenu) ||
            (rla1.isActive && button.subMenu && button.collapsed)
              ? 'secondary-active'
              : 'secondary'
          "
          [routerLink]="button.routerLink"
          [queryParams]="button.queryParams"
          routerLinkActive="active"
          class="!justify-normal"
          (click)="toggleMenu(rla1, button)"
          [class.!hidden]="
            role && button.roles && !button.roles.includes(role)
          ">
          <mat-icon class="material-icons-outlined">{{ button.icon }}</mat-icon>
          {{ button.text }}

          @if (button.subMenu) {
            <mat-icon iconPositionEnd class="material-icons-outlined">
              keyboard_arrow_down
            </mat-icon>
          }
        </button>
        @if (button.subMenu && rla1.isActive && !button.collapsed) {
          @for (subButton of button.subMenu; track $index) {
            <button
              mat-flat-button
              [routerLink]="subButton.routerLink"
              routerLinkActive="active"
              #rla2="routerLinkActive"
              [color]="
                rla2.isActive ? 'secondary-active submenu' : 'secondary submenu'
              "
              class="!justify-normal !text-xs"
              *catAiRoleAccess="subButton.roles || []">
              {{ subButton.text | titlecase }}
            </button>
          }
        }
      }
      @if (canChangeForm) {
        <button
          #rla2="routerLinkActive"
          mat-flat-button
          [color]="rla2.isActive ? 'secondary-active' : 'secondary'"
          routerLink="/form-builder"
          routerLinkActive="active"
          class="!justify-normal">
          <mat-icon class="material-icons-outlined">format_shapes</mat-icon>
          Form Builder
        </button>
      }
    } @else if (type === SidebarType.SETTINGS) {
      @for (button of settingsButtons; track button.icon) {
        <button
          #rla1="routerLinkActive"
          mat-flat-button
          [color]="
            (rla1.isActive && !button.subMenu) ||
            (rla1.isActive && button.subMenu && button.collapsed)
              ? 'secondary-active'
              : 'secondary'
          "
          [routerLink]="button.routerLink"
          [queryParams]="button.queryParams"
          routerLinkActive="active"
          class="!justify-normal"
          (click)="toggleMenu(rla1, button)"
          [class.!hidden]="
            role && button.roles && !button.roles.includes(role)
          ">
          <mat-icon class="material-icons-outlined">{{ button.icon }}</mat-icon>
          {{ button.text }}
        </button>
      }
    }
  </div>
</aside>
