<div class="flex flex-col gap-6 p-6">
  <div class="flex justify-between items-baseline">
    <h2>Added Participants</h2>
    <button mat-flat-button color="accent" (click)="openParticipantsModal()">
      Add participants
    </button>
  </div>
  <div class="flex gap-4">
    <cat-ai-search
      (search)="filterBySearch($event)"
      wrapperClasses="shadow-xs"></cat-ai-search>
    <cat-ai-selector
      label="Select department"
      [options]="departments$ | async"
      (change)="changeDepartment($event)"
      [resetOption]="true"
      wrapperClasses="shadow-xs"></cat-ai-selector>
    <cat-ai-selector
      label="Select job category"
      [options]="jobCategories$ | async"
      (change)="changeJobCategory($event)"
      [resetOption]="true"
      wrapperClasses="shadow-xs"></cat-ai-selector>
  </div>

  <cat-ai-table
    [config]="config"
    [rows]="data$ | async"
    (remove)="removeSelection($event)"
    [isLoading]="loading"></cat-ai-table>
</div>
