<div class="p-6 flex flex-col gap-6">
  <button mat-button routerLink="../../.." class="self-start">
    <mat-icon class="relative -top-0.5">chevron_left</mat-icon>
    Back to training
  </button>
  <div class="bg-white p-6 rounded-lg shadow-sm">
    <cat-ai-training-details
      [training]="currentTraining$ | async"></cat-ai-training-details>
  </div>
  <div>
    <cat-ai-training-reports-tab
      [tableConf]="config"
      [loading]="loading"
      [data]="reports$ | async"
      [jobCategories]="jobCategories$ | async"
      (updateFilters)="updateReportsFilters($event)"
      (openReport)="gotoMemberReport($event)"></cat-ai-training-reports-tab>
  </div>
</div>
