@if (training) {
  @if (training.cover) {
    <div
      class="rounded-md h-64 bg-cover bg-gray-100 w-full bg-center"
      [style.background-image]="'url(' + training.cover + ')'"></div>
  }
  <div class="max-w-5xl text-gray-700">
    <div class="flex flex-col gap-1 mb-4">
      <p class="text-gray-500 text-xs">#{{ training.id }}</p>
      <h3 class="font-semibold">{{ training.name }}</h3>
      <div class="flex gap-4 text-gray-500 text-sm">
        <p>Created By: {{ training.created_by?.fullname }}</p>
        <p>
          {{ training.created_at | date: 'HH:mm, dd/MM/yyyy' }}
        </p>
      </div>
    </div>
    <p class="text-md text-gray-700">
      {{ training.description }}
    </p>
  </div>
}
