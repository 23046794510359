<div #wrap class="w-full overflow-hidden shadow-sm rounded-lg bg-white">
  <h4 #title class="p-4">Training Notifications</h4>
  <div
    class="w-full overflow-y-auto px-4 flex flex-col gap-4"
    [style.max-height]="maxHeight - title.clientHeight + 'px'"
    [style.min-height]="maxHeight - title.clientHeight + 'px'">
    <cat-ai-notifications-list
      [notifications]="notifications"
      [showTimeAgo]="true"
      (dismiss)="dismiss($event)"
      (navigateTo)="notificationService.navigateTo($event)"
      wrapperClasses="flex flex-col gap-3"></cat-ai-notifications-list>
  </div>
</div>
