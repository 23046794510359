<button
  type="button"
  class="w-10 p-2 rounded-sm border border-transparent text-gray"
  [ngClass]="{
    'bg-gray-100': active,
    'opacity-0': hidden,
  }"
  [disabled]="hidden">
  <ng-content></ng-content>
</button>
