import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  FormlyFieldConfig,
  FormlyFormOptions,
  FormlyModule,
} from '@ngx-formly/core';
import { SupportFormData, SupportFormFields } from './support-form.config';
import { MatButton } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { IssueReportRequest, IssueReportService } from '@cat-ai-us-fe/api';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { VERSION_TOKEN } from '@cat-ai-us-fe/shared/util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationCardComponent } from '@cat-ai-us-fe/shared/ui';
import { DEFAULT_NOTIFICATION_DISMISS_DURATION } from '@cat-ai-us-fe/shared/data-access';
import { FormMessageComponent } from '@cat-ai-us-fe/forms/ui';
import { HttpStatusCode } from '@angular/common/http';
import { ImageUploaderComponent } from '../image-uploader/image-uploader.component';
import UrgencyEnum = IssueReportRequest.UrgencyEnum;

@Component({
  selector: 'cat-ai-support-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    MatButton,
    MatDialogClose,
    MatDivider,
    FormMessageComponent,
    ImageUploaderComponent,
  ],
  templateUrl: './support-form.component.html',
})
export class SupportFormComponent {
  @Output() closeForm: EventEmitter<void> = new EventEmitter();

  protected supportForm = new FormGroup({});
  protected supportModel: SupportFormData = {
    urgency: UrgencyEnum.Backlog,
    description: '',
    attachments: [],
  };
  protected supportFields: FormlyFieldConfig[] = SupportFormFields();
  protected isLoading = false;
  protected options: FormlyFormOptions = {};
  protected serverError = '';
  protected isClearFiles = false;

  private readonly destroyRef = inject(DestroyRef);
  private feVersion = inject(VERSION_TOKEN);
  private snackBar = inject(MatSnackBar);

  constructor(private reportService: IssueReportService) {}

  protected onClose() {
    if (this.isLoading) {
      return;
    }
    this.closeForm.emit();
  }

  protected onSupportModelUpdates(model: IssueReportRequest) {
    this.serverError = '';
    this.supportModel = model;
  }

  protected onSend() {
    if (this.supportForm.invalid) {
      this.supportForm.markAllAsTouched();
      return;
    }
    this.isLoading = true;
    const request: IssueReportRequest = {
      ...this.supportModel,
      frontend_version: this.feVersion,
    };
    this.reportService
      .issueReportCreate({
        issueReportRequest: request,
      })
      .pipe(
        finalize(() => (this.isLoading = false)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.options.resetModel?.({
            urgency: UrgencyEnum.Backlog,
            description: '',
          });
          this.isClearFiles = true;
          this.showSuccessPopUp();
          this.onClose();
        },
        error: (error) => {
          if (error.status === HttpStatusCode.BadRequest) {
            const errObj = error?.error;
            let message = '';
            Object.keys(errObj).forEach((key, index) => {
              const subject = (errObj[key] as string[])?.join(',');
              if (index) {
                message += `<br/>`;
              }
              message += `${key}: ${subject}`;
            });
            this.serverError = message;
          } else {
            this.serverError = error?.error?.detail || '';
          }
        },
      });
  }

  protected onFileLinksUpdates(files: string[]) {
    this.supportModel.attachments = files;
  }

  private showSuccessPopUp() {
    const data = {
      title: 'Success!',
      message: 'Your report has been submitted successfully',
      urgency: 'high',
      type: 'success',
    };
    this.snackBar.openFromComponent(NotificationCardComponent, {
      data,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-snackbar',
      duration: DEFAULT_NOTIFICATION_DISMISS_DURATION,
    });
  }
}
