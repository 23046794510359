<div class="fixed bottom-5 right-2 z-[101]">
  <button
    mat-fab
    type="button"
    color="accent"
    class="help-center-btn"
    (click)="togglePopup()"
    matTooltip="Report a Problem"
    data-testid="help-center-btn">
    <mat-icon class="material-icons-outlined !size-5 text-xl leading-none">
      back_hand
    </mat-icon>
  </button>
  <div
    class="bg-white p-6 absolute bottom-0 right-0 w-[350px] double-shadow rounded-lg opacity-0 [visibility:hidden] translate-y-4 transition-[opacity,visibility,transform]"
    [ngClass]="{
      'opacity-100 [visibility:visible] !translate-y-0': isOpen,
    }"
    data-testid="help-center-popup">
    <div class="flex items-center justify-between mb-6">
      <h3>Report a problem</h3>
      <button
        type="button"
        mat-icon-button
        (click)="togglePopup()"
        data-testid="help-center-popup-close-btn">
        <mat-icon>unfold_less</mat-icon>
      </button>
    </div>
    <cat-ai-support-form (closeForm)="togglePopup()" />
  </div>
</div>
