import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cat-ai-form-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-message.component.html',
})
export class FormMessageComponent {
  @Input() message!: string;
  @Input() set type(value: 'error' | 'success' | 'info') {
    switch (value) {
      case 'success':
        this.schemeClasses = 'bg-green-100 text-green-800';
        break;
      case 'error':
        this.schemeClasses = 'bg-red-100 text-red-800';
        break;
      case 'info':
      default:
        this.schemeClasses = 'bg-blue-100 text-blue-900';
        break;
    }
  }
  schemeClasses = 'bg-blue-100 text-blue-900';
}
