<div class="min-h-screen flex bg-gray-50">
  @if (
    fullScreenPage ||
    (fullScreenPageSupervisorOnly && userRole === UserRole.Supervisor)
  ) {
    <main class="grow">
      <router-outlet></router-outlet>
    </main>
  } @else {
    <cat-ai-aside></cat-ai-aside>
    <div class="flex flex-col w-full">
      <cat-ai-header
        [notifications]="notifications"
        [pageTitle]="title$ | async"
        [subTitle]="subTitle$ | async"
        (showNotification)="onToggle()"></cat-ai-header>
      <div class="flex flex-row grow relative">
        <main class="grow">
          <router-outlet></router-outlet>
        </main>
        @if (showSideNotifications) {
          <cat-ai-notification-sidebar
            (click)="$event.stopPropagation()"
            [notifications]="notifications"></cat-ai-notification-sidebar>
        }
      </div>
    </div>
  }

  <cat-ai-help-center-button />

  @if (feVersion && beVersion) {
    <span class="fixed bottom-0 right-0 px-1 text-gray-400 text-sm bg-gray-50">
      BE: {{ beVersion }}|FE: {{ feVersion }}
    </span>
  }
</div>
