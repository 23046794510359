import { Permission } from '@cat-ai-us-fe/api';

export interface AuthStateModel {
  token: string | null;
  username: string | null;
  permissions: Permission[];
}

export class Login {
  static readonly type = '[Auth] Login';

  constructor(public payload: { username: string; password: string }) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class LoadUserPermissions {
  static readonly type = '[Auth] Load User Permissions';
}
