<div
  class="{{
    documentMode ? 'grid grid-cols-2 gap-x-12' : 'flex flex-col gap-6'
  }} ">
  @for (question of questions; track $index) {
    <div
      id="quiz-question-{{ uniqueId }}-{{ question.id }}"
      class="flex flex-col"
      [class.gap-6]="!documentMode">
      <div>
        <p class="text-gray-500">Question &#x2116;{{ $index + 1 }}</p>
        <span class="text-gray-500">
          <b
            [class.text-xs]="documentMode"
            class="text-xl font-semibold tracking-tight text-gray-900">
            {{ question.text }}
          </b>
          @if (!documentMode) {
            ({{ question.score }} Points)
          }
        </span>
      </div>
      <div>
        @if (!documentMode) {
          <label class="text-gray-500 font-semibold">Options</label>
        }
        <ul>
          @for (variant of question.variants; let last = $last; track $index) {
            @if (!!answers) {
              @if (documentMode) {
                @if (variant.correctOption || variant.nonCorrectOption) {
                  <li
                    class="p-2 text-xs text-black rounded-xs border w-full"
                    [ngClass]="{
                      'bg-success-100 border-success-500':
                        variant.correctOption,
                      'bg-error-muted border-error': variant.nonCorrectOption,
                    }"
                    [class.my-2]="!last">
                    <p>
                      {{ variant.text }}
                    </p>
                  </li>
                }
              } @else {
                <li
                  class="px-3 py-2 rounded-xs border max-w-[320px] {{
                    variant.correctOption
                      ? 'bg-success-100 border-success-500'
                      : variant.nonCorrectOption
                        ? 'bg-error-muted border-error'
                        : ''
                  }}"
                  [class.my-2]="!last">
                  <p>
                    {{ variant.text }}
                  </p>
                </li>
              }
            } @else {
              <li
                class="px-3 py-2 rounded-xs border max-w-[320px] {{
                  variant.correct
                    ? 'bg-success-100 border-success-500'
                    : 'bg-gray-50 border-gray-100'
                }}"
                [class.my-2]="!last">
                <p>
                  {{ variant.text }}
                </p>
              </li>
            }
          }
        </ul>
      </div>
    </div>
  }
</div>
