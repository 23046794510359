/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShallowUserRequest } from './shallow-user-request';

export interface PatchedPublicTrainingRequest {
  name?: string;
  description?: string;
  cover?: string;
  start?: string;
  end?: string;
  /**
   * * `draft` - Draft * `active` - Active * `completed` - Completed * `failed` - Failed * `archived` - Archived
   */
  status?: PatchedPublicTrainingRequest.StatusEnum;
  certificate_valid_until?: string | null;
  seconds_to_complete?: number | null;
  number_of_attempts?: number;
  pass_score?: number;
  created_by?: ShallowUserRequest;
}
export namespace PatchedPublicTrainingRequest {
  export type StatusEnum =
    | 'draft'
    | 'active'
    | 'completed'
    | 'failed'
    | 'archived';
  export const StatusEnum = {
    Draft: 'draft' as StatusEnum,
    Active: 'active' as StatusEnum,
    Completed: 'completed' as StatusEnum,
    Failed: 'failed' as StatusEnum,
    Archived: 'archived' as StatusEnum,
  };
}
