/**
 * cat-ai-us API
 * API for Cat-AI-US project
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ShallowUserRequest } from './shallow-user-request';

export interface IssueReportRequest {
  description: string;
  /**
   * * `high` - High * `medium` - Medium * `low` - Low * `backlog` - Backlog
   */
  urgency: IssueReportRequest.UrgencyEnum;
  created_by?: ShallowUserRequest;
  frontend_version?: string | null;
  attachments?: Array<string>;
}
export namespace IssueReportRequest {
  export type UrgencyEnum = 'high' | 'medium' | 'low' | 'backlog';
  export const UrgencyEnum = {
    High: 'high' as UrgencyEnum,
    Medium: 'medium' as UrgencyEnum,
    Low: 'low' as UrgencyEnum,
    Backlog: 'backlog' as UrgencyEnum,
  };
}
