import { Form } from '@cat-ai-us-fe/api';

export interface FormsStateModel {
  forms: Form[];
}

export class LoadForms {
  static readonly type = '[Forms] Load Forms';
}

export class AddNewForm {
  constructor(public form: Form) {}
  static readonly type = '[Forms] Add New Form';
}
