<div
  mat-dialog-header
  class="flex justify-between gap-3 px-6 py-5 items-center">
  <h2>Upload attachment</h2>
  <mat-icon class="material-icons-outlined" mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content>
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
  <button mat-flat-button mat-dialog-close color="basic">Cancel</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="model.attachment">
    Add
  </button>
</mat-dialog-actions>
