<div class="p-6 bg-gray-50 h-screen flex flex-col gap-3">
  <header class="flex justify-between">
    <button mat-mini-fab color="basic square" (click)="goBack()">
      <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
    <div class="flex gap-2">
      <button
        mat-flat-button
        color="basic"
        class="!min-w-24"
        (click)="onCreate(true)"
        [loading]="saving">
        Safe as draft
      </button>
      <button
        mat-flat-button
        color="accent"
        class="!min-w-24"
        (click)="onCreate()"
        [loading]="saving || loading">
        @if (training && training.id) {
          Save
        } @else {
          Create
        }
      </button>
    </div>
  </header>
  <div class="flex flex-grow gap-3 justify-center">
    <aside
      class="bg-white border border-gray-100 shadow-sm rounded-lg flex flex-col items-start p-4 min-w-[200px]"
      [class.bg-loading]="loading || saving">
      @for (view of StepperView | enumToArray; track $index) {
        <button
          mat-flat-button
          (click)="changeView($index)"
          [class.mat-accent]="$index === selectedView"
          class="w-full !justify-start"
          color="clear">
          {{ view }}
        </button>
      }
    </aside>
    <div class="bg-white border border-gray-100 shadow-sm rounded-lg w-[860px]">
      <mat-tab-group
        class="header-less-tabs"
        [(selectedIndex)]="selectedView"
        [preserveContent]="true"
        animationDuration="0"
        [class.bg-loading]="loading || saving">
        <mat-tab>
          <cat-ai-training-form
            (modelChanged)="onTrainingFormChanged($event)"
            [config]="formFields"
            [model]="trainingModel"></cat-ai-training-form>
        </mat-tab>
        <mat-tab>
          <cat-ai-training-participants
            (patricipantsChange)="setParticipants($event)"
            [trainingId]="
              training && training.id
            "></cat-ai-training-participants>
        </mat-tab>
        <mat-tab>
          <cat-ai-training-quiz
            [trainingDetails]="trainingModel"
            [training]="training"
            (setQuiz)="setQuizData($event)"></cat-ai-training-quiz>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
