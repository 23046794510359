export interface MediaConfig {
  id: string;
  type: 'document' | 'image';
  name?: string;
  src: string;
  size?: number;
  status?: 'uploading' | 'failed' | string;
  errorMsg?: string;
}

export const MediaActionMessage = {
  delete: 'Delete',
  preview: 'Preview',
  uploading: 'Uploading',
  failed: 'Failed',
};
