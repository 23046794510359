import { FormlyFieldConfig } from '@ngx-formly/core';
import { IssueReportRequest } from '@cat-ai-us-fe/api';
import UrgencyEnum = IssueReportRequest.UrgencyEnum;
import { AbstractControl } from '@angular/forms';

export interface SupportFormData {
  urgency: UrgencyEnum;
  description: string;
  attachments?: string[];
}

export const UrgencyLabels = {
  high: 'High - 24-hour turnaround',
  medium: 'Medium - 48-hour turnaround',
  low: 'Low - 96-hour turnaround',
  backlog: 'No priority',
};

export interface UploadedFile extends File {
  id?: string;
}
export const SupportFormFields = (): FormlyFieldConfig[] => [
  {
    key: 'urgency',
    type: 'cat-ai-select',
    className: 'block my-6',
    props: {
      label: 'Urgency',
      required: false,
      placeholder: 'Select',
      options: Object.values(UrgencyEnum).map((priority) => ({
        label: UrgencyLabels[priority],
        value: priority,
      })),
    },
  },
  {
    key: 'description',
    type: 'cat-ai-textarea',
    className: 'block mb-6',
    props: {
      label: 'Description',
      required: true,
      placeholder: 'Describe the problem you faced',
      charactersLimitCounter: 1024,
      maxLength: 1024,
    },
    validators: {
      customError: {
        expression: (c: AbstractControl) => {
          if (!c.value) {
            return true;
          }
          return /\S/g.test(c.value);
        },
        message: 'The description must not be blank',
      },
    },
  },
];

export const SupportImagesFields = (): FormlyFieldConfig[] => [
  {
    key: 'files',
    type: 'cat-ai-file',
    className: 'block mb-6',
    props: {
      multiple: true,
      label: 'Screenshot',
      extensionsText: 'SVG, PNG, JPG or GIF (2560 x 1440 max)',
      enablePaste: true,
      hideFiles: true,
    },
  },
];
