<a
  class="rounded-md p-3 flex flex-col gap-2 h-full shadow-xs {{
    wrapperClasses
  }}"
  (click)="taskClick.emit(task.id)">
  <!-- item header -->
  <div class="flex items-center justify-between">
    <div class="flex items-center">
      <p class="!text-inherit text-xs">
        {{ task.created_at | dateFormat }}
      </p>
    </div>
  </div>
  <!-- item body -->
  <h5 class="text-gray-800">{{ task.title }}</h5>
  <p class="break-all !text-inherit text-xs">{{ task.scope }}</p>
</a>
