<div class="{{ wrapperClasses }}">
  <div class="cat-ai-scroll overflow-x-auto">
    <table class="w-full table-fixed {{ tableClasses }}">
      <thead>
        <tr>
          @if (config.selectionEnabled) {
            <th class="cat-ai-table-header-cell checkbox">
              <mat-checkbox
                class="example-margin"
                [checked]="selectedRows.length === rows?.length"
                [indeterminate]="
                  rows?.length &&
                  selectedRows.length &&
                  selectedRows.length !== rows?.length
                "
                (change)="
                  masterToggleRowSelection($event.checked)
                "></mat-checkbox>
            </th>
          }
          @for (
            column of config.columns;
            track column;
            let columnIndex = $index
          ) {
            @if (visibleColumns.includes(columnIndex)) {
              <th
                *catAiRoleAccess="column.roles || []"
                class="cat-ai-table-header-cell">
                {{ column.label }}
              </th>
            }
          }
          @if (actionsEnabled) {
            <th class="cat-ai-table-header-cell">
              {{ config.actionsColumnLabel }}
            </th>
          }
          @if (config.columnsConfigEnabled) {
            <th class="text-right cat-ai-table-header-cell">
              <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>settings</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                @for (
                  columnInner of config.columns;
                  track columnInner;
                  let index = $index
                ) {
                  <label
                    [for]="index + '-input'"
                    (click)="$event.stopPropagation()"
                    mat-menu-item>
                    <mat-checkbox
                      [id]="index.toString()"
                      [checked]="visibleColumns.includes(index)"
                      (change)="toggleColumnVisibility(index)"></mat-checkbox>
                    <span>{{ columnInner.label }}</span>
                  </label>
                }
              </mat-menu>
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @if (isLoading) {
          @if (config.selectionEnabled) {
            <tr class="cat-ai-table-cell checkbox">
              <td
                class="cat-ai-table-cell"
                [colSpan]="visibleColumns.length === 0 ? 2 : 1">
                <mat-checkbox class="invisible"></mat-checkbox>
              </td>
            </tr>
          }
          @for (_ of skeletons; track _) {
            <tr class="cat-ai-table-body-row">
              @for (
                column of config.columns;
                track column;
                let columnIndex = $index;
                let last = $last
              ) {
                @if (visibleColumns.includes(columnIndex)) {
                  <td
                    class="cat-ai-table-cell animate-pulse"
                    *catAiRoleAccess="column.roles || []"
                    [colSpan]="
                      last && config.columnsConfigEnabled ? 2 : 1
                    "></td>
                }
              }
            </tr>
          }
        } @else {
          @for (row of rows; track row; let rowIndex = $index) {
            <tr class="cat-ai-table-body-row" (click)="rowClick.emit(row)">
              @if (config.selectionEnabled) {
                <td
                  class="cat-ai-table-cell checkbox"
                  [colSpan]="visibleColumns.length === 0 ? 2 : 1">
                  <mat-checkbox
                    [checked]="selectedRows.includes(row)"
                    (change)="
                      toggleRowSelection($event.checked, row)
                    "></mat-checkbox>
                </td>
              }
              @for (
                column of config.columns;
                track column;
                let columnIndex = $index;
                let last = $last
              ) {
                @if (visibleColumns.includes(columnIndex)) {
                  <td
                    class="cat-ai-table-cell"
                    *catAiRoleAccess="column.roles || []"
                    [colSpan]="
                      last && config.columnsConfigEnabled && !actionsEnabled
                        ? 2
                        : 1
                    ">
                    @if (!column.componentRef) {
                      <span
                        [innerHTML]="column | getTableValueByKey: row"></span>
                    } @else {
                      <ng-container
                        *ngComponentOutlet="
                          column.componentRef;
                          inputs: row | getTableComponentInputs: column
                        "></ng-container>
                    }
                  </td>
                }
              }
              @if (actionsEnabled) {
                <td
                  class="cat-ai-table-cell cat-ai-table-actions"
                  [colSpan]="config.columnsConfigEnabled ? 2 : 1">
                  <cat-ai-table-actions
                    [data]="row"
                    [actions]="config.actions"
                    [removeActionIcon]="config.removeActionIcon || 'cross'"
                    [searchActionIcon]="config.searchActionIcon"
                    (edit)="edit.emit($event)"
                    (print)="print.emit($event)"
                    (remove)="remove.emit($event)"
                    (search)="search.emit($event)"
                    (archive)="archive.emit($event)"
                    (viewDocument)="viewDocument.emit($event)"
                    (download)="download.emit($event)"></cat-ai-table-actions>
                </td>
              }
            </tr>
          } @empty {
            <tr class="h-12 py-4 px-6 relative">
              <p class="absolute top-4 bottom-4 left-0 right-0 text-center">
                {{ config.emptyStateLabel || 'No data' }}
              </p>
            </tr>
          }
        }
      </tbody>
    </table>
  </div>
  @if (!config.paginationDisabled) {
    <cat-ai-table-footer
      [totalCountLabel]="config.totalCountLabel"
      [pagination]="pagination"
      (pageChange)="changePage($event)"
      (perPageChange)="changePerPage($event)"></cat-ai-table-footer>
  }
</div>
