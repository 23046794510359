<mat-form-field class="w-full {{ wrapperClasses }}">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <mat-select
    #select
    (selectionChange)="change.emit($event.value)"
    [(ngModel)]="value">
    @if (resetOption) {
      <mat-option>None</mat-option>
    }
    @for (option of options; track $index) {
      <mat-option [value]="option[valueBindedProperty]">
        {{ option[labelBindedProperty] }}
      </mat-option>
    }
    @for (option of enumOptions; track $index) {
      <mat-option [value]="option[valueBindedProperty]">
        {{ option[labelBindedProperty] | titlecase }}
      </mat-option>
    }
    @if (!enumOptions?.length && !options?.length) {
      No data available :(
    }
  </mat-select>

  <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
</mat-form-field>
