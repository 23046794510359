import { Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormlyModule } from '@ngx-formly/core';
import { FORM_FIELDS, FORM_MODEL } from './generator-form.const';
import { MatIconModule } from '@angular/material/icon';
import { NgTemplateOutlet } from '@angular/common';
import { QuestionFormComponent } from '@cat-ai-us-fe/trainings/ui';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import {
  GenerateQuizRequest,
  PrivateQuestion,
  PublicTraining,
} from '@cat-ai-us-fe/api';
import { TrainingFormType } from '@cat-ai-us-fe/trainings/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonLoadingDirective } from '@cat-ai-us-fe/shared/util';

type GenerateQuizFormData = Omit<
  GenerateQuizRequest,
  'question' | 'name' | 'description' | 'materials'
>;

type QuestionFormData = Pick<PrivateQuestion, 'score' | 'text' | 'variants'>;

@Component({
  selector: 'cat-ai-generate-quiz-modal',
  standalone: true,
  imports: [
    MatDialogModule,
    FormlyModule,
    MatButtonModule,
    MatIconModule,
    NgTemplateOutlet,
    QuestionFormComponent,
    MatButtonLoadingDirective,
  ],
  templateUrl: './generate-quiz-modal.component.html',
})
export class GenerateQuizModalComponent implements OnInit {
  form = new FormGroup({});
  model = FORM_MODEL;
  fields = FORM_FIELDS;
  questionForms = new FormArray<FormGroup>([]);
  generating = false;
  private apiService = inject(TrainingsService);
  private dialogRef = inject(MatDialogRef);
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      trainingForm: TrainingFormType;
      training: PublicTraining;
      questions?: PrivateQuestion[];
    },
  ) {}

  ngOnInit(): void {
    if (this.data.questions) {
      this.setQuestionFormsData(this.data.questions);
    }
  }

  create() {
    const value: Partial<PrivateQuestion>[] = this.getQuestionsDataFromForm();

    this.dialogRef.close({
      questions: value,
      ...this.model,
    });
  }

  addQuestion(index = 0) {
    const newQuestionForm = new FormGroup({
      text: new FormControl('', Validators.required),
      single: new FormControl(true, Validators.required),
      variants: new FormControl([]),
      score: new FormControl(0, Validators.required),
    });

    this.questionForms.insert(index + 1, newQuestionForm);
  }

  remove(index: number) {
    this.questionForms.removeAt(index);
  }

  generateQuestions() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.generating = true;
    const { name, description, materials } = this.data.trainingForm;
    const payload: GenerateQuizRequest = {
      ...(this.form.value as GenerateQuizFormData),
      questions: this.getQuestionsDataFromForm(),
      name,
      description,
      materials: materials.map((m) => m.value || m),
    };

    this.apiService
      .generateQuestions(payload)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.generating = false;
        this.setQuestionFormsData(res.questions);
      });
  }

  private getQuestionsDataFromForm() {
    const values = this.questionForms.value;
    return values;
  }

  private setQuestionFormsData(questions: QuestionFormData[]) {
    this.questionForms = new FormArray(
      questions.map(
        (question) =>
          new FormGroup({
            text: new FormControl(question.text, Validators.required),
            single: new FormControl(true, Validators.required),
            variants: new FormControl(question.variants),
            score: new FormControl(question.score, Validators.required),
          }),
      ),
    );
  }
}
