<div class="flex items-center justify-between py-3 px-6">
  <button
    mat-flat-button
    color="basic"
    (click)="changePage(currentPage - 1)"
    [disabled]="pagination.page === 1"
    class="shadow-xs">
    Previous
    <mat-icon>arrow_back</mat-icon>
  </button>

  <!-- PAGINATION START -->
  <div>
    <cat-ai-pagination-button
      [hidden]="currentPage < pagesDisplayCount"
      (click)="changePage(step)">
      1
    </cat-ai-pagination-button>
    <cat-ai-pagination-button
      [hidden]="currentPage === step"
      (click)="changePage(currentPage - step)"></cat-ai-pagination-button>
    @for (page of pagesButtons; track page) {
      <cat-ai-pagination-button
        [active]="page === currentPage"
        (click)="changePage(page)">
        {{ page }}
      </cat-ai-pagination-button>
    }
    <cat-ai-pagination-button
      [hidden]="pagesCount <= step || currentPage >= pagesCount"
      (click)="changePage(currentPage + step)">
      ...
    </cat-ai-pagination-button>
    <cat-ai-pagination-button
      [hidden]="
        pagesCount <= pagesDisplayCount || pagesCount - currentPage <= step
      "
      (click)="changePage(pagesCount)">
      {{ pagesCount }}
    </cat-ai-pagination-button>
  </div>
  <!-- PAGINATION END -->
  <button
    mat-flat-button
    color="basic"
    (click)="changePage(currentPage + 1)"
    [disabled]="pagination.page === pagesButtons.length || !pagesButtons.length"
    class="shadow-xs">
    Next
    <mat-icon iconPositionEnd class="material-icons-outlined">
      arrow_forward
    </mat-icon>
  </button>
</div>
