<div class="flex flex-row text-sm gap-5 text-gray-700">
  <div>
    @if (!data.type) {
      <div
        class="rounded-full flex items-center justify-center h-10 w-10 cat-urgent-badge-{{
          data.notification.urgency
        }}">
        <mat-icon class="material-icons-outlined text-base text-center">
          {{ data.notification | notificationIcon }}
        </mat-icon>
      </div>
    } @else {
      <div
        class="rounded-full flex items-center justify-center h-10 w-10 bg-green-100 text-green-700">
        <mat-icon class="material-icons-outlined text-base text-center">
          check
        </mat-icon>
      </div>
    }
  </div>
  <div>
    <div class="max-w-80">
      <p class="font-semibold text-gray-900">
        {{ data.title || data.notification.title | titlecase }}
      </p>
      <p
        class="text-gray-600"
        [innerHTML]="data.message || data.notification.message"></p>
    </div>
    @if (!data.title && data.notification.kind) {
      <div class="relative -left-2">
        <button mat-button (click)="dismiss()">Dismiss</button>
        <button
          mat-button
          (click)="notificationsService.navigateTo(data.notification)"
          color="primary">
          View the task
        </button>
      </div>
    }
  </div>
  <div>
    <mat-icon (click)="snackBarRef.dismissWithAction()" class="cursor-pointer">
      close
    </mat-icon>
  </div>
</div>
