<div
  class="flex"
  [ngClass]="{
    'flex-row items-start gap-8': props['isHorizontal'],
    'flex-col gap-1.5': !props['isHorizontal'],
  }">
  <label
    class="block font-semibold leading-3"
    [for]="id"
    [ngClass]="{
      'basis-[160px] shrink-0': props['isHorizontal'],
    }">
    {{ props.label }}
    @if (!props.required && props.label) {
      <span class="text-gray-400">(Optional)</span>
    }
  </label>
  <mat-form-field class="w-full">
    <div
      [ngxMatTimepicker]="picker"
      [format]="24"
      [ngModel]="maskedInput.value">
      <input
        #maskedInput
        matInput
        [formlyAttributes]="field"
        [placeholder]="props.placeholder || ''"
        [formControl]="formControl"
        [id]="id"
        [dropSpecialCharacters]="false"
        mask="Hh:m0" />
    </div>
    <ngx-mat-timepicker
      #picker
      appendToInput="true"
      (timeChanged)="dispatch($event)"
      (timeSet)="dispatch($event)"></ngx-mat-timepicker>
  </mat-form-field>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"></cat-ai-error-message>
</div>
