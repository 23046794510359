import { Component, ElementRef, HostListener } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { SupportFormComponent } from '@cat-ai-us-fe/help-center/feature';

@Component({
  selector: 'cat-ai-help-center-button',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    NgClass,
    MatTooltipModule,
    SupportFormComponent,
  ],
  templateUrl: './help-center-button.component.html',
})
export class HelpCenterButtonComponent {
  public isOpen = false;
  constructor(private elRef: ElementRef) {}

  togglePopup() {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;

    if (this.elRef.nativeElement.contains(targetElement)) {
      return;
    }
    if (targetElement.closest('.cdk-overlay-container')) {
      return;
    }
    if (targetElement.closest('.help-center-delete-btn')) {
      return;
    }

    this.isOpen = false;
  }
}
