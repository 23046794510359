<div>
  <form [formGroup]="imagesForm">
    <formly-form
      [form]="imagesForm"
      [fields]="imagesFields"
      [options]="options"
      [model]="imagesModel"
      (modelChange)="onFilesModelChange($event)" />
  </form>
  <div class="mb-3">
    <div class="flex items-start gap-0.5 flex-wrap">
      @for (file of mediaList; track file.id) {
        <cat-ai-media-card [media]="file" (action)="onFileAction($event, file)" />
      }
    </div>
    @if (mediaList.length) {
      <p class="!mt-1 text-gray-600">{{mediaList.length}}/6</p>
    }
  </div>
</div>
