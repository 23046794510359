import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SelectorComponent } from '@cat-ai-us-fe/shared/ui';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PrivateAnswerVariant, PrivateQuestion } from '@cat-ai-us-fe/api';
import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'cat-ai-question-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    SelectorComponent,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    DragDropModule,
    NgTemplateOutlet,
  ],
  templateUrl: './question-form.component.html',
})
export class QuestionFormComponent {
  @Input() index: number | null = null;
  @Input() loadingState = false;
  @Input() form: FormGroup | null = null;
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter<Partial<PrivateQuestion>>();

  questionTypeOptions = [
    { name: 'Single select', id: true },
    { name: 'Multi select', id: false },
  ];
  questionSceleton: Partial<PrivateQuestion> = {
    text: '',
    variants: [
      { id: 0, text: '' },
      { id: 0, text: '' },
      { id: 0, text: '' },
    ],
  };

  get variantsValue(): PrivateAnswerVariant[] {
    return this.form?.controls.variants.value;
  }

  changeCorrentVariant(variantIndex: number) {
    this.variantsValue[variantIndex].correct =
      !this.variantsValue[variantIndex].correct;
    this.form?.controls.variants.setValue(this.variantsValue);
  }

  changeSingleCorrentVariant(variantIndex: number) {
    this.variantsValue[variantIndex].correct = true;
    this.variantsValue.forEach((variant, i) => {
      if (i === variantIndex) {
        variant.correct = true;
      } else {
        variant.correct = false;
      }
    });
    this.form?.controls.variants.setValue(this.variantsValue);
  }

  changeVariantText(variantIndex: number, text?: string) {
    this.variantsValue[variantIndex].text = text || '';
    this.form?.controls.variants.setValue(this.variantsValue);
  }

  addOption() {
    this.form?.controls.variants.patchValue([
      ...this.variantsValue,
      {
        text: '',
        correct: false,
      },
    ]);
  }

  removeOption(index: number) {
    this.variantsValue.splice(index, 1);
    this.form?.controls.variants.setValue(this.variantsValue);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
  }
}
