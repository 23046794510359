import { FilledFormAttachmentDetail, FormQuestion } from '@cat-ai-us-fe/api';
import { emailPattern, phonePattern } from '../types/field-patterns';
import { map, Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

export function mapGridCols(cols: number) {
  switch (cols) {
    case 1:
      return 'grid gap-2 grid-cols-1';
    case 2:
      return 'grid gap-2 grid-cols-2';
    case 3:
      return 'grid gap-2 grid-cols-3';
    case 4:
      return 'grid gap-2 grid-cols-4';

    default:
      return 'grid gap-2 grid-cols-1';
  }
}

export function mapGridColStart(cols: number) {
  switch (cols) {
    case 1:
      return 'col-start-1';
    case 2:
      return 'col-start-2';
    case 3:
      return 'col-start-3';
    case 4:
      return 'col-start-4';

    default:
      return 'col-start-1';
  }
}

export function mapGridColSpan(cols: number) {
  switch (cols) {
    case 1:
      return 'col-span-1';
    case 2:
      return 'col-span-2';
    case 3:
      return 'col-span-3';
    case 4:
      return 'col-span-4';

    default:
      return 'col-span-1';
  }
}

export function mapFormQuestionToFormlyConfig(
  question: FormQuestion,
  getRequestFieldOptions: (url: string) => Observable<{
    results: Record<string, string | number>[];
  }>,
  attachments?: FilledFormAttachmentDetail[],
) {
  const minDate = new Date();
  minDate.setTime(0);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 100);

  const maxFileSizeMB = 200;

  const key = uuid();
  const sharedProps = {
    label: question.title,
    placeholder: question.placeholder ?? undefined,
    multiple: question.is_multiselect,
    required: question.is_required,
  };

  const className = `${question.column_start ? mapGridColStart(question.column_start) : ''} ${question.column_width ? mapGridColSpan(question.column_width) : ''}`;

  const mappings = {
    [FormQuestion.DataTypeEnum.Date]: {
      key,
      type: 'cat-ai-datepicker',
      className,
      props: {
        ...sharedProps,
        min: minDate.getTime(),
        max: maxDate.getTime(),
      },
    },
    [FormQuestion.DataTypeEnum.Select]: {
      key,
      type: 'cat-ai-select',
      className,
      props: {
        ...sharedProps,
        options: question.choices?.map((choice) => ({
          label: choice.title,
          value: String(choice.id),
        })),
      },
    },
    [FormQuestion.DataTypeEnum.File]: {
      key,
      type: 'cat-ai-file',
      className,
      props: {
        ...sharedProps,
        questionId: question.id,
        attachments,
      },
      hooks: {
        onInit: (field: any) => {
          field.formControl?.valueChanges.subscribe((value: File[]) => {
            if (!value || value.length === 0 || !question.id) {
              return;
            }

            if (value.some((file) => file.size > maxFileSizeMB * 1048576)) {
              alert(`Maximum file size is ${maxFileSizeMB} mb`);
              field.formControl.setValue('');
              return;
            }
          });
        },
      },
    },
    [FormQuestion.DataTypeEnum.InputText]: {
      key,
      type: 'cat-ai-input',
      className,
      props: {
        ...sharedProps,
        maxLength: 200,
      },
    },
    [FormQuestion.DataTypeEnum.Checkbox]: {
      key,
      type: 'cat-ai-checkbox',
      className,
      props: {
        ...sharedProps,
        options: question.choices?.map((choice) => ({
          label: choice.title,
          value: choice.id,
        })),
      },
    },
    [FormQuestion.DataTypeEnum.Radio]: {
      key,
      type: 'cat-ai-radio',
      className,
      props: {
        ...sharedProps,
        options: question.choices?.map((choice) => ({
          label: choice.title,
          value: choice.id,
        })),
      },
    },
    [FormQuestion.DataTypeEnum.Canvas]: {
      key,
      type: 'cat-ai-textarea',
      className,
      props: {
        ...sharedProps,
        rows: 5,
        maxLength: 200,
      },
    },
    [FormQuestion.DataTypeEnum.InputEmail]: {
      key,
      type: 'cat-ai-input',
      className,
      props: {
        ...sharedProps,
        type: 'email',
        className,
        pattern: emailPattern,
      },
    },
    [FormQuestion.DataTypeEnum.InputNumber]: {
      key,
      type: 'cat-ai-input',
      className,
      props: {
        ...sharedProps,
        type: 'number',
        className,
        min: -1_000_000_000,
        max: 1_000_000_000,
      },
    },
    [FormQuestion.DataTypeEnum.InputPhone]: {
      key,
      type: 'cat-ai-input',
      className,
      props: {
        ...sharedProps,
        type: 'tel',
        className,
        pattern: phonePattern,
        maxLength: 14,
      },
    },
    [FormQuestion.DataTypeEnum.Textarea]: {
      key,
      type: 'cat-ai-textarea',
      className,
      props: {
        ...sharedProps,
        maxLength: 1000,
      },
    },
    [FormQuestion.DataTypeEnum.Request]: {
      key,
      type: 'cat-ai-request',
      className,
      props: {
        ...sharedProps,
        request: question.request_params,
      },
      hooks: {
        onInit: (field: any) => {
          const request = question.request_params;
          field.props.options = getRequestFieldOptions(request.url).pipe(
            map((r) =>
              r.results.map((item) => {
                return {
                  label: String(item[(request.mapping as any).label]),
                  value: String(item[(request.mapping as any).value]),
                };
              }),
            ),
          );
        },
      },
    },
  };

  return mappings[question.data_type];
}
