import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  SearchComponent,
  SelectorComponent,
  TableComponent,
} from '@cat-ai-us-fe/shared/ui';
import { MatIconModule } from '@angular/material/icon';
import { TableConfig } from '@cat-ai-us-fe/shared/util';
import {
  CompanyGroup,
  JobCategory,
  Participant,
  TrainingsParticipantsListRequestParams,
} from '@cat-ai-us-fe/api';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cat-ai-training-participants-tab',
  standalone: true,
  imports: [
    SearchComponent,
    SelectorComponent,
    MatIconModule,
    TableComponent,
    MatButtonModule,
  ],
  templateUrl: './training-participants-tab.component.html',
})
export class TrainingParticipantsTabComponent {
  @Input() participants!: Participant[] | null;
  @Input() jobCategories!: JobCategory[] | null;
  @Input() departments!: CompanyGroup[] | null;
  @Input() loading = false;
  @Input() tableConf!: TableConfig<Participant>;
  @Input() disableParticipantsButton = false;
  @Output() updateFilters = new EventEmitter<
    Partial<TrainingsParticipantsListRequestParams>
  >();
  @Output() openParticipantsModal = new EventEmitter();
  @Output() viewUserTasks = new EventEmitter();
  @Output() downloadParticipantsCsv = new EventEmitter();
}
