<div class="p-6 flex flex-col gap-6">
  <div class="flex justify-between">
    <h2>Training report</h2>
    <div class="flex gap-2 items-center">
      <button
        #printBtn
        mat-icon-button
        color="accent"
        (click)="download(printBtn, true)"
        [disabled]="loading"
        class="leading-none">
        <mat-icon class="material-icons-outlined">print</mat-icon>
      </button>
      <button
        #downloadBtn
        mat-button
        color="accent"
        [disabled]="loading"
        (click)="download(downloadBtn)">
        <mat-icon class="material-icons-outlined">file_download</mat-icon>
        Download
      </button>
    </div>
  </div>
  <mat-tab-group mat-stretch-tabs="false" class="text-gray-500">
    <mat-tab label="Preview">
      <cat-ai-training-report-snapshot
        [training]="currentTraining$ | async"
        [groupReport]="groupReport$ | async"
        [groupParticipantReport]="groupParticipantReport$ | async"
        [participantReport]="participantReport$ | async"
        [attempt]="attempt$ | async"
        [group]="group$ | async"
        [user]="user$ | async"
        [company]="company$ | async"
        (updateLoadingState)="
          setLoadingState($event)
        "></cat-ai-training-report-snapshot>
    </mat-tab>
  </mat-tab-group>
</div>
