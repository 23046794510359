<div class="p-6 flex flex-col gap-6">
  <div class="flex justify-between">
    <h2>Certificate of Completion</h2>
    <div class="flex gap-2 items-center">
      <button
        #printBtn
        mat-icon-button
        color="accent"
        (click)="download(printBtn, true)"
        class="leading-none"
        [disabled]="loading">
        <mat-icon class="material-icons-outlined">print</mat-icon>
      </button>
      <button
        #downloadBtn
        mat-button
        color="accent"
        (click)="download(downloadBtn)"
        [disabled]="loading">
        <mat-icon class="material-icons-outlined">file_download</mat-icon>
        Download
      </button>
    </div>
  </div>
  <mat-tab-group mat-stretch-tabs="false" class="text-gray-500">
    <mat-tab label="Preview">
      <cat-ai-certificate-snapshot
        [user]="user$ | async"
        [training]="training$ | async"
        [certificate]="certificate$ | async"
        (updateLoadingState)="
          setLoadingState($event)
        "></cat-ai-certificate-snapshot>
    </mat-tab>
  </mat-tab-group>
</div>
