import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MediaActionMessage, MediaConfig } from './media-card.config';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { ResolveAttachmentUrlPipe } from '@cat-ai-us-fe/shared/data-access';

@Component({
  selector: 'cat-ai-media-card',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    MatIcon,
    MatProgressSpinner,
    MatFabButton,
    MatTooltip,
  ],
  templateUrl: './media-card.component.html',
})
export class MediaCardComponent {
  @Input() set media(data: MediaConfig) {
    this.mediaData = data;
    switch (this.mediaData.status) {
      case 'failed': {
        this.updateStatusMessage(MediaActionMessage.failed);
        this.showControls = true;
        return;
      }
      case 'uploading': {
        this.updateStatusMessage(MediaActionMessage.uploading);
        this.showControls = true;
        return;
      }
      default: {
        this.updateStatusMessage('');
        this.showControls = false;
      }
    }
  }
  @Output() action: EventEmitter<'delete' | 'preview'> = new EventEmitter<
    'delete' | 'preview'
  >();
  protected mediaData!: MediaConfig;
  protected showControls = false;
  protected statusMessage = '';
  protected readonly MediaActionMessage = MediaActionMessage;

  updateStatusMessage(message: string): void {
    if (this.mediaData.status && this.statusMessage) {
      return;
    }
    this.statusMessage = message;
  }

  changeControlsView(value: boolean) {
    if (!this.mediaData.status) {
      this.showControls = value;
    }
  }
}
