<div
  class="flex"
  [ngClass]="{
    'flex-row items-start gap-8': props['isHorizontal'],
    'flex-col gap-1.5': !props['isHorizontal'],
  }">
  <label
    class="block font-semibold leading-3"
    [for]="id"
    [ngClass]="{
      'basis-[160px] shrink-0': props['isHorizontal'],
    }">
    {{ props.label }}
    @if (!props.required) {
      <span class="text-gray-400">(Optional)</span>
    }
  </label>
  <mat-form-field class="w-full">
    <input
      [formlyAttributes]="field"
      [placeholder]="props.placeholder || ''"
      [id]="id"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto" />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="select($event.option.value)">
      @for (
        option of props.options | formlySelectOptions: field | async;
        track option?.value
      ) {
        <mat-option [value]="option">{{ option.label }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <div class="flex gap-2 flex-wrap">
    @for (
      selection of formControl && formControl.value;
      track selection?.value
    ) {
      <div class="border border-gray-300 p-2 rounded-md flex gap-4">
        <label class="block font-semibold leading-5">
          {{ selection.label }}
        </label>
        <mat-icon
          class="cursor-pointer text-gray-500 !w-5 !h-5 !text-xl !leading-none"
          (click)="remove($event, $index)">
          close
        </mat-icon>
      </div>
    }
  </div>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"></cat-ai-error-message>
</div>
