<div
  class="flex"
  [ngClass]="{
    'flex-row items-start gap-8': props['isHorizontal'],
    'flex-col gap-1.5': !props['isHorizontal'],
  }">
  <label
    class="block font-semibold leading-3"
    [for]="id"
    [ngClass]="{
      'basis-[160px] shrink-0': props['isHorizontal'],
    }">
    {{ props.label }}
    @if (!props.required && props.label) {
      <span class="text-gray-400">(Optional)</span>
    }
  </label>
  <mat-form-field class="w-full">
    <textarea
      matInput
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder || ''"
      [id]="id"
      [rows]="props.rows"
      [cols]="props.cols"
      (input)="calculateCharacters()"></textarea>

    @if (props.description) {
      <mat-hint class="w-full text-right text-neutral-600 text-xs">
        {{ props.description }}
      </mat-hint>
    }
  </mat-form-field>
  @if (props.charactersLimitCounter) {
    <p class="text-gray-600">
      {{ charactersQuantity }}/{{ props.charactersLimitCounter }}
    </p>
  }
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"></cat-ai-error-message>
</div>
