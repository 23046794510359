<div>
  <span
    class="block mb-1.5 font-semibold leading-3 cursor-default text-base-muted"
    (click)="open()"
    [id]="id + '_label'">
    {{ props.label }}
    @if (!props.required && props.label) {
      <span class="text-gray-400">(Optional)</span>
    }
  </span>

  <mat-form-field class="w-full">
    <mat-select
      #select
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder || ''"
      [aria-labelledby]="id + '_label'"
      [id]="id"
      [multiple]="props['multiple']">
      @if (props['multiple']) {
        <mat-select-trigger class="space-x-2">
          @for (value of formControl.value; track value) {
            <cat-ai-multiselect-option
              [label]="
                (
                  value
                  | findOption
                    : (props.options | formlySelectOptions: field | async)
                )?.label || ''
              "
              [index]="$index"
              (removeClick)="
                onRemoveOptionClick($event)
              "></cat-ai-multiselect-option>
          }
        </mat-select-trigger>
      }
      @for (
        option of props.options | formlySelectOptions: field | async;
        track option
      ) {
        <mat-option [value]="option.value" class="capitalize">
          {{ option.label }}
        </mat-option>
      }
    </mat-select>
    <mat-icon matSuffix class="!pl-0">keyboard_arrow_down</mat-icon>
  </mat-form-field>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"></cat-ai-error-message>
</div>
