<div class="flex flex-row gap-4 min-h-32">
  <!-- Graphics -->
  <div
    class="flex-2 flex items-start justify-start flex-auto bg-gray-25 rounded-sm border border-gray-200 min-h-full max-w-fit px-6 gap-6">
    <div class="highcharts-wrap">
      @if (chart.series?.length && chart.title?.text) {
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="chart"></highcharts-chart>
      }
    </div>

    <div
      class="h-full flex-auto w-fit flex flex-row gap-4 justify-center text-xs">
      <div class="flex flex-col gap-2 justify-center">
        <div class="flex flex-row items-center gap-2">
          <div class="bg-success-500 h-4 w-4 rounded-xs"></div>
          <p>Completed</p>
          <p class="font-bold text-sm">{{ stats?.passed }}</p>
        </div>
        <div class="flex flex-row items-center gap-2">
          <div class="bg-black h-4 w-4 rounded-xs"></div>
          <p>Not Started</p>
          <p class="font-bold text-sm">{{ stats?.not_started }}</p>
        </div>
      </div>
      <div class="flex flex-col gap-2 justify-center">
        <div class="flex flex-row items-center gap-2">
          <div class="bg-blue-400 h-4 w-4 rounded-xs"></div>
          <p>In Progress</p>
          <p class="font-bold text-sm">{{ stats?.in_progress }}</p>
        </div>
        <div class="flex flex-row items-center gap-2">
          <div class="bg-red-500 h-4 w-4 rounded-xs"></div>
          <p>Failed</p>
          <p class="font-bold text-sm">{{ stats?.failed }}</p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="p-6 flex-1 flex gap-4 flex-col items-start justify-center bg-gray-25 border border-gray-200 rounded-sm min-h-full">
    <p class="text-gray-900 text-sm">Total watched</p>
    <p class="font-bold text-3xl">
      {{ stats?.total_watched | secondsToTime }}
    </p>
  </div>
  <div
    class="p-6 flex-1 flex gap-4 flex-col items-start justify-center bg-gray-25 border border-gray-200 rounded-sm min-h-full">
    <p class="text-gray-900 text-sm">Avg. Per User</p>
    <p class="font-bold text-3xl">
      {{ stats?.average_per_user | secondsToTime }}
    </p>
  </div>
</div>
