<div
  class="flex"
  [ngClass]="{
    'flex-row items-start gap-8': props['isHorizontal'],
    'flex-col gap-1.5': !props['isHorizontal'],
  }">
  <label
    class="block font-semibold leading-3"
    [for]="id"
    [ngClass]="{
      'basis-[160px] shrink-0': props['isHorizontal'],
    }">
    {{ props.label }}
    @if (!props.required) {
      <span class="text-gray-400">(Optional)</span>
    }
  </label>
  <mat-form-field class="w-full">
    @if (props['addonLeft']?.icon) {
      <mat-icon
        matIconPrefix
        [catLazyLoadIcon]="props['addonLeft'].icon"
        class="text-blue-500"></mat-icon>
    }
    <input
      matInput
      [formControl]="formControl"
      [formlyAttributes]="field"
      [placeholder]="props.placeholder || ''"
      [id]="id"
      [type]="props.type || 'text'"
      [required]="props.required || false" />
  </mat-form-field>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"></cat-ai-error-message>
</div>
