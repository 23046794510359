import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddNewForm, FormsStateModel, LoadForms } from './forms.actions';
import { Injectable } from '@angular/core';
import { Form, FormsService, PaginatedFormList } from '@cat-ai-us-fe/api';
import { tap } from 'rxjs';

@State<FormsStateModel>({
  name: 'forms',
  defaults: {
    forms: [],
  },
})
@Injectable()
export class FormsState {
  @Selector()
  static getForms(state: FormsStateModel): Form[] {
    return state.forms;
  }

  constructor(private readonly formsService: FormsService) {}

  @Action(LoadForms)
  loadForms(ctx: StateContext<FormsStateModel>) {
    return this.formsService
      .formsList({
        pageSize: 100,
      })
      .pipe(
        tap((response: PaginatedFormList) => {
          ctx.patchState({
            forms: response.results,
          });
        }),
      );
  }

  @Action(AddNewForm)
  addNewForm(ctx: StateContext<FormsStateModel>, action: AddNewForm) {
    const currentState = ctx.getState();
    const formsArray = [...currentState.forms];
    formsArray.unshift(action.form);

    ctx.patchState({
      forms: formsArray,
    });
  }
}
