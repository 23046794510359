import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  DateFormatType,
  getFileNameFromUrl,
  transformDate,
} from '@cat-ai-us-fe/shared/util';
import { debounceTime, map, switchMap } from 'rxjs';
import { TrainingsService } from '@cat-ai-us-fe/trainings/data-access';
import { TrainingFormType } from '@cat-ai-us-fe/trainings/util';

export const FORM_FIELDS = (
  trainingsService: TrainingsService,
): FormlyFieldConfig[] => {
  return [
    {
      key: 'name',
      type: 'cat-ai-input',
      className: 'col-span-3 ',
      props: {
        placeholder: 'Training name',
        label: 'Training name',
        required: true,
      },
    },
    {
      key: 'pass_score',
      type: 'cat-ai-input',
      className: 'col-span-3',
      props: {
        placeholder: 'Pass score',
        label: 'Pass score',
        required: true,
      },
    },
    {
      key: 'autocomplete_search',
    },
    {
      key: 'materials',
      type: 'cat-ai-autocomplete',
      className: 'col-span-3',
      wrappers: ['cat-ai-training-material-selector'],
      props: {
        placeholder: 'Search ...',
        label: 'Training material',
        required: true,
      },
      hooks: {
        onInit: (field: any) => {
          const searchControl = field.parent.get(
            'autocomplete_search',
          )?.formControl;
          field.props.options = searchControl.valueChanges.pipe(
            debounceTime(500),
            switchMap(() =>
              trainingsService
                .getMaterials({
                  search: searchControl.value,
                })
                .pipe(
                  map((r) =>
                    r.map((m) => {
                      return {
                        label:
                          m.title ||
                          getFileNameFromUrl(m.material_link?.file?.url),
                        value: m.material_link?.id,
                      };
                    }),
                  ),
                ),
            ),
          );
        },
      },
    },
    {
      key: 'coverUrl',
    },
    {
      key: 'cover',
      type: 'cat-ai-file',
      className: 'row-span-2 col-span-2 row-start-1 col-start-4',
      props: {
        label: 'Training Cover',
        extensionsText: 'SVG, PNG, JPG or GIF (max. 800x400px)',
      },
    },
    {
      key: 'start_date',
      type: 'cat-ai-datepicker',
      className: 'col-span-2 col-start-1',
      props: {
        label: 'Start date',
        placeholder: 'mm/dd/yyyy',
        required: true,
      },
    },
    {
      key: 'start_time',
      type: 'cat-ai-timepicker',
      className: 'col-span-1',
      props: {
        label: 'Start time',
        placeholder: 'hh:mm',
        required: true,
      },
    },
    {
      key: 'end_date',
      type: 'cat-ai-datepicker',
      className: 'col-span-2 col-start-1',
      props: {
        label: 'Due date',
        placeholder: 'mm/dd/yyyy',
        min: transformDate(new Date(), DateFormatType.YearMonthDay) as any,
        required: true,
      },
    },
    {
      key: 'end_time',
      type: 'cat-ai-timepicker',
      className: 'col-span-1',
      props: {
        label: 'End time',
        required: true,
        placeholder: 'hh:mm',
      },
    },
    {
      key: 'certificate_valid_until',
      type: 'cat-ai-datepicker',
      className: 'col-start-1 col-span-2',
      props: {
        label: 'Certificate valid until',
        placeholder: 'mm/dd/yyyy',
        min: transformDate(new Date(), DateFormatType.YearMonthDay) as any,
      },
    },
    {
      key: 'description',
      type: 'cat-ai-textarea',
      className: 'col-span-3 col-start-1',
      props: {
        required: true,
        label: 'Description',
        rows: 4,
        placeholder: 'It will help you...',
      },
    },
  ];
};

export const TRAINING_FORM_MODEL: TrainingFormType = {
  name: '',
  materials: [],
  pass_score: 0,
  cover: undefined,
  start_date: transformDate(new Date(), DateFormatType.YearMonthDay),
  start_time: new Date().toTimeString().slice(0, 5),
  certificate_valid_until: undefined,
  end_date: undefined,
  end_time: '23:59',
  description: '',
};
