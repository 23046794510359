<div>
  <label class="block mb-1.5 font-semibold leading-3" [for]="id + 0">
    {{ props.label }}
    @if (!props.required && props.label) {
      <span class="text-gray-400">(Optional)</span>
    }
  </label>
  <div class="flex gap-1 flex-wrap">
    @for (
      option of props.options | formlySelectOptions: field | async;
      track option;
      let i = $index
    ) {
      <mat-checkbox
        [formControl]="formControl"
        [formlyAttributes]="field"
        [value]="option.value"
        [id]="id + i"
        disableRipple="true">
        {{ option.label }}
      </mat-checkbox>
    }
  </div>
  <cat-ai-error-message
    [formControl]="formControl"
    [props]="props"></cat-ai-error-message>
</div>
