import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  AuthStateModel,
  LoadUserPermissions,
  Login,
  Logout,
} from './auth.actions';
import { AuthService } from '../auth.service';
import { Permission, PermissionsService } from '@cat-ai-us-fe/api';
import { of } from 'rxjs';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    username: null,
    permissions: [],
  },
})
@Injectable()
export class AuthState {
  @Selector()
  static getToken(state: AuthStateModel): string {
    return state.token as string;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Selector()
  static getUserPermissions(state: AuthStateModel): Permission[] {
    return state.permissions;
  }

  constructor(
    private authService: AuthService,
    private permissionsService: PermissionsService,
  ) {}

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return this.authService.login(action.payload).pipe(
      tap(({ token }) => {
        ctx.patchState({
          token: token,
          username: action.payload.username,
        });
      }),
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    localStorage.removeItem('userPermissions');
    ctx.setState({
      token: null,
      username: null,
      permissions: [],
    });
  }

  @Action(LoadUserPermissions)
  loadUserPermissions(ctx: StateContext<AuthStateModel>) {
    const permissions = localStorage.getItem('userPermissions');
    if (permissions) {
      ctx.patchState({
        permissions: JSON.parse(permissions) as Permission[],
      });
      return of(permissions);
    }
    return this.permissionsService
      .permissionsList({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pageSize: 'null',
      })
      .pipe(
        tap((result) => {
          localStorage.setItem('userPermissions', JSON.stringify(result));
          ctx.patchState({
            permissions: result as unknown as Permission[],
          });
        }),
      );
  }
}
